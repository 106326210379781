/**
 * Discount tuple
 * @type {Map<string, {name: string, value: string}>}
 */
const discountFormats = new Map([
    ['percent', { name: 'Porcentagem', value: 'percent'}],
    ['currency', { name: 'Moeda', value: 'currency'}],
])

/**
 * Item Struct
 * @type {{quantity: number, discount_format: string, final_price: number, name: null, discount: number, id: null, type: null, health_plan_type: null, unit_price: number}}
 */
const item = {
    item_id: null, // procedure item id, not form id
    budget_id: null,
    health_plan_id: null,
    name: null, // procedure item name at the date of addition
    type: null, // procedure type:
    health_plan_name: null,
    quantity: 1,
    unit_price: 0.01,
    discount: 0,
    discount_format: discountFormats.get('currency'),
    final_price: 0.0
}

const itemValidations = {
    item_id: {
        expr: ['string', 'not_null'],
        msg: ['ID de Item Inválido', 'Por favor, informe um Item']
    },
    name: {
        expr: ['string', 'not_null', ['length', '>=', 2]],
        msg: ['O produto precisa ter um nome', 'O nome não pode ser vazio', 'Nome inválido']
    },
    type: {
        expr: ['string', 'not_null', ['value', 'in', ['Produto', 'Procedimento', 'Despesa', 'Presencial', 'Centro cirúrgico', 'Exame', 'Retorno', 'Telemedicina']]],
        msg: ['O item precisa ter um tipo', 'Tipo não faz parte da Lista de Tipos']
    },
    discount_format: {
        expr: ['string', ['value', 'in', Array.from(discountFormats.keys())]],
        msg: [
            'Por favor, informe o tipo de Desconto ou deixe o valor como zero.',
            'Tipo de desconto Inválido.'
        ]
    },
    discount: {
        expr: ['number', ['value', '>=', 0]],
        msg: ['Desconto Inválido.', 'O desconto precisa ser MAIOR que zero.']
    },
    unit_price: {
        expr: ['number', ['value', '>=', 0.01]],
        msg: ['Valor Inválido.','O valor Unitário precisa ser maior que 0,01.']
    },
    quantity: {
        expr: ['number', ['value', '>', 0]],
        msg: ['Por favor, informe uma quantidade válida.', 'A quantidade de Itens precisa ser MAIOR que zero.']
    },
    final_price: {
        expr: ['number', ['value', '>', 0]],
        msg: ['Preço Final Inválido.', 'Preço Final não pode ser Menor ou Igual a Zero.']
    },

}

export {
    discountFormats,
    item,
    itemValidations,
}