<template>
  <div class="procedure-item-form">
    <b-row>

      <b-col class="col-8">
        <b-form-group class="form-group">
          <label for="item-select">Procedimento/Item</label>
          <ItemHealthPlanSelector
            :healthPlan="value.health_plan_id"
            @select="({ item }) => onItemSelect(item)"
            disableHealthPlanSelection
            :allowedTypes="[
                'PROCEDURE',
                'APPOINTMENT',
                'EXAM',
                'SURGICAL',
                'RETURN',
                'TELEMEDICINE'
            ]"
          />
          <div v-if="! form.item_id && value.items.length === 0" class="custom-invalid-feedback">Por favor, Selecione um Item</div>
        </b-form-group>
      </b-col>

      <b-col class="col-4">
        <b-form-group class="form-group">
          <label for="item-combo">Quantidade</label>
          <b-form-input
              id="item_quantity"
              placeholder="Quantidade"
              type="number"
              v-model="form.quantity"
              :class="{ 'red-border': parseFloat(form.quantity) < 1}"
          />
          <div v-if="parseFloat(form.quantity) < 1" class="custom-invalid-feedback">Por favor, informe uma quantidade válida</div>
        </b-form-group>
      </b-col>

    </b-row>
<!--  Values row  -->
    <b-row>
      <b-col class="col-3">
        <b-form-group class="form-group">
          <label for="item_unit_price">Valor Unitário</label>
          <money
              class="form-control"
              id="item_unit_price"
              placeholder="Preço Unitário"
              v-model="form.unit_price"
              :class="{ 'red-border': minimumValue}"
          />
          <div v-if="minimumValue" class="custom-invalid-feedback">Por favor, informe um valor mínimo válido</div>
        </b-form-group>
      </b-col>
      <!-- discount format -->
      <b-col class="col-3">
        <b-form-group class="form-group">
          <label for="item_discount_format">Formato Desc.</label>
          <multiselect
              class="procedure-item form-control"
              id="item_discount_format"
              v-model="form.discount_format"
              :showLabels="false"
              :showNoResults="false"
              :searchable="false"
              :allow-empty="false"
              track-by="value"
              label="name"
              placeholder="Selecionar..."
              :options="Array.from(discountFormats.values())"
          >
            <template #caret>
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template #singleLabel="props">
              <div>
                {{props.option.name}}
              </div>
            </template>
            <template #noOptions>
              Nenhuma opção
            </template>
            <template #noResult>
              Nenhum resultado
            </template>
          </multiselect>
          <div v-if="! form.discount_format?.value" class="custom-invalid-feedback">Por favor, informe um Tipo de Desconto</div>
        </b-form-group>
      </b-col>
      <!-- discount -->
      <b-col class="col-2">
        <b-form-group class="form-group">
          <label for="item-unit-price">Desconto</label>
          <template v-if="(form.discount_format) && form.discount_format.value !== discountFormats.get('currency').value">
            <b-form-input
              v-model="form.discount"
              class="form-control"
              type="number"
              maxlength="2"
              id="item_discount"
              placeholder="Desconto..."
              :showLabels="true"
              :class="{ 'red-border': parseFloat(form.discount) < 0}"
            />
          </template>
          <template v-else>
            <money
              v-model="form.discount"
              class="form-control"
              id="item_unit_price"
              placeholder="Preço Unitário"
              :class="{ 'red-border': parseFloat(form.discount) < 0}"
            />
          </template>
          <div v-if="parseFloat(form.discount) < 0.00" class="custom-invalid-feedback">Por favor, digite um valor maior/igual a zero</div>
        </b-form-group>
      </b-col>
      <!-- budget final amount -->
      <b-col class="col-2">
        <b-form-group class="form-group">
          <label for="item_final_price">Valor</label>
          <money
              class="form-control"
              id="item_final_price"
              placeholder="Valor Proposta"
              :value="finalPrice"
              disabled
          />
        </b-form-group>
      </b-col>
      <!-- action buttons -->
      <b-col class="col-2">
        <b-form-group class="form-group">
          <div class="wrapper-button">
            <b-button
                class=""
                variant="primary"
                size="xg"
                @click="add"
            >
              Adicionar Item
            </b-button>
          </div>
        </b-form-group>
      </b-col>
    </b-row>


  </div>
</template>
<script>
import { item, discountFormats, itemValidations } from '@sp/config/item'
import {parseType} from "@/utils/itemHelper"
import {validate} from "@/utils/formValidator"

export default {
  name: "ProcedureItemForm",
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    ItemHealthPlanSelector: () => import('@items/components/ItemHealthPlanSelector'),
  },
  props: {
    /**
     * Item Object
     * @param {Object}
     */
    value: {
      type: Object,
      default: null
    },
    clinic: {
      type: Object,
      default: null
    }
  },

  computed: {
    /**
     * @todo find a way to deduplicate final price computation
     * @returns {Number}
     */
    finalPrice() {
      let total = parseFloat(this.form.unit_price * this.form.quantity).toFixed(2)

      if(this.form.discount > 0.00) {

        if (this.form.discount_format.value === discountFormats.get('currency').value) {
          total = parseFloat(total - this.form.discount).toFixed(2)
        } else {
          total = parseFloat(total - ((total/100) * this.form.discount)).toFixed(2)
        }

      }

      return parseFloat(total)
    },
    /**
     * validates if a minimum value was input
     * @returns {boolean}
     */
    minimumValue() {
      return parseFloat(this.form.unit_price) < 0.00
    }
  },

  data() {
    return {
      form: {...item},
      discountFormats,
    }
  },
  methods: {
    add() {
      const data = {...this.form}
      const hasItem = this.findItem(data)

      if(! hasItem) {

        data.final_price = this.computeItemFinalPrice(data)
        data.discount_format = data.discount_format.value
        data.health_plan_name = this.value.health_plan_id.company_name
        const validItem = this.validateItem(data)
        if(validItem) {
          this.value.items.push(data)
          this.form = {...item}
          this.form.name = data.name
          this.form.type = data.type
          this.$toast.success(`Item ${data.name} incluído com sucesso.`)

          this.form = {...item}
        }
      }
      else{
        this.$toast.warning(`O item ${data.name} já existe na lista de Itens/Procedimentos.`)
      }
    },
    // emitted events
    onItemSelect(selectedItem) {
      this.form.item_id = selectedItem.id
      this.form.name = selectedItem.name
      this.form.type = parseType({item: selectedItem})
      this.form.health_plan_name = selectedItem.health_plan_name
      this.form.health_plan_id = selectedItem.health_plan_id

      if (selectedItem.health_plan_props) {
        this.form.unit_price = selectedItem.health_plan_props.value
      }
    },

    // helper methods
    /**
     * Get the final amount of a budget item
     * @todo find a way to deduplicate this method with the computed property finalPrice
     * @param item
     * @returns {Number}
     */
    computeItemFinalPrice(item) {
      let total = parseFloat(item.unit_price * item.quantity).toFixed(2)

      if(item.discount > 0) {

        if (item.discount_format.value === discountFormats.get('currency').value) {
          total = parseFloat(total - item.discount).toFixed(2)
        } else {
          total = parseFloat(total - ((total/100) * item.discount)).toFixed(2)
        }
      }

      return parseFloat(total)
    },

    findItem(data) {
      return this.value.items.find(item => item.item_id === data.item_id)
    },

    validateItem(data) {
      let r = null

      r = validate(itemValidations, data)

      if(r.length > 0) {
        this.$toast.error('Por favor, revise os dados para inserir um Procedimento/Item')
        return false
      }

      return true
    }
  }
}
</script>

<style lang="scss">
.procedure-item.form-control {
  padding: 0;
}
.red-border{
  border: 0.66px red solid !important;
  color: red !important;
}
</style>

<style scoped lang="scss">
.wrapper-button {
  width: 100%;
  display: inline-flex;
  margin: 27px 0 0 0 !important;
  justify-content: flex-end;

  &.deletable {
    justify-content: space-between;
  }


  button {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;

    &.danger {
      color: var(--states-error);
    }
  }
}
</style>