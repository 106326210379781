import {isArray, isNull, isNumber, isString, isUndefined} from "lodash"

const validate_string = (str) => {
    return isString(str)
}

const validate_is_null = (val) => {
    return isNull(val) || isUndefined(val)
}

const validate_number = (num) => {
    return isNumber(parseFloat(num))
}

const validate_float = (num) => {
    return /^-?(?:\d+|\d*\.\d+)(?:[eE][-+]?\d+)?$/.test(num)
}
/**
 *
 * @param {String|Array} expr
 * @param {Any} val
 * @returns {boolean}
 */
const parse_expr = (expr, val) => {
    let r = false
    if(isString(expr)) {
        switch (expr) {
            case 'string':
                r = validate_string(val)
            break
            case 'not_null':
                r = ! validate_is_null(val)
            break
            case 'float':
                r = validate_float(val)
            break
            case 'number':
                r = validate_number(val)
            break

        }
    }

    if(isArray(expr)) {
        r = parse_array_expr(expr, val)
    }

    return r
}

/**
 * Parses an Array of validations
 * @param {Array} expr
 * @param {Any} val
 * @returns {boolean}
 */
const parse_array_expr = (expr, val) => {
    let r = false
    switch (expr[0]) {
        case 'length':
            if(expr[1] === '>') {
                r = val?.length > expr[2]
            }

            if(expr[1] === '<') {
                r = val?.length < expr[2]
            }

            if(expr[1] === '>=') {
                r = val?.length >= expr[2]
            }

            if(expr[1] === '<=') {
                r = val?.length <= expr[2]
            }

        break
        case 'value':
            if(expr[1] === 'in') {
                r = expr[2].includes(val)
            }

            if(expr[1] === '=') {
                if(isNumber(val))
                    r = parseFloat(val) === expr[2]
                else {
                    r = val === expr[2]
                }
            }

            if(expr[1] === '>') {
                r = parseFloat(val) > expr[2]
            }

            if(expr[1] === '>=') {
                r = parseFloat(val) >= expr[2]
            }
        break
    }

    return r
}

const validate = (validations, data) => {
    const r = []
    let valid = true

    Object.keys(validations).forEach((key) => {
        validations[key].expr.forEach((expr,i) => {
            valid = parse_expr(expr, data[key])
            if(! valid) {
                r.push(validations[key].msg[i])
            }
        })
    })

    return r
}

export {
    parse_expr,
    validate,
}